import axios from "axios";

const _username = "ALAM_PH";
const _password = "NNM0n3pz3D2021";

export const API = axios.create({
  baseURL: `https://zed.ph/alm/api`,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: _username,
    password: _password,
  },
});

export const PAYMENT = axios.create({
  baseURL: `http://18.138.205.159:10209/api`,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "wvcbw+kMDHkYA82+aJ/QY1wQiIVjRnRZk51YJL+MTsDaFyhqH4PTbk0NguSg+RKMha9X/fSZQg==",
  },
});
