export default {
  state: {
    clickId: "",
    selectedGame: {},
    cancelMobileNumber: "",
    channel: "",
  },

  mutations: {
    SET_CLICKID(state, payload) {
      state.clickId = payload;
    },

    SET_SELECTEDGAME(state, payload) {
      state.selectedGame = payload;
    },

    SET_CANCELMOBILENUMBER(state, payload) {
      state.cancelMobileNumber = payload;
    },

    SET_CHANNEL(state, payload) {
      state.channel = payload;
    },
  },

  actions: {
    ADD_CLICKID: ({ commit }, payload) => {
      commit("SET_CLICKID", payload);
    },

    ADD_SELECTEDGAME: ({ commit }, payload) => {
      commit("SET_SELECTEDGAME", payload);
    },

    ADD_CANCELMOBILENUMBER: ({ commit }, payload) => {
      commit("SET_CANCELMOBILENUMBER", payload);
    },

    ADD_CHANNEL: ({ commit }, payload) => {
      commit("SET_CHANNEL", payload);
    },
  },

  getters: {
    GET_CLICKID: (state) => state.clickId,
    GET_SELECTEDGAME: (state) => state.selectedGame,
    GET_CANCELMOBILENUMBER: (state) => state.cancelMobileNumber,
    GET_CHANNEL: (state) => state.channel,
  },
};
