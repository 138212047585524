import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue"),
  },

  {
    path: "/all-games",
    name: "AllGames",
    component: () =>
      import(/* webpackChunkName: "allgames" */ "../views/AllGames.vue"),
  },

  // {
  //   path: "/how-to-pay",
  //   name: "HowToPay",
  //   component: () =>
  //     import(/* webpackChunkName: "howtoplay" */ "../views/HowToPay.vue"),
  // },

  {
    path: "/how-to-cancel",
    name: "HowToCancel",
    component: () =>
      import(/* webpackChunkName: "howtoplay" */ "../views/HowToCancel.vue"),
  },
  {
    path: "/cancel",
    name: "CancelDetails",
    component: () =>
      import(/* webpackChunkName: "howtoplay" */ "../views/CancelDetails.vue"),
  },

  {
    path: "/game-details/:id",
    name: "GameDetails",
    component: () =>
      import(/* webpackChunkName: "gamedetails" */ "../views/GameDetails.vue"),
  },

  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  },

  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(
        /* webpackChunkName: "terms-and-conditions" */ "../views/TermsAndCondition.vue"
      ),
  },

  // {
  //   path: "/payment",
  //   name: "Payment",
  //   component: () =>
  //     import(/* webpackChunkName: "support" */ "../views/Payment.vue"),
  // },

  // {
  //   path: "/success",
  //   name: "Success",
  //   component: () =>
  //     import(/* webpackChunkName: "support" */ "../views/SuccessPage.vue"),
  // },

  // {
  //   path: "/failed",
  //   name: "Failed",
  //   component: () =>
  //     import(/* webpackChunkName: "support" */ "../views/FailedPage.vue"),
  // },

  // {
  //   path: "/terms-and-conditions",
  //   name: "TermsAndConditions",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "support" */ "../views/TermsAndCondition.vue"
  //     ),
  // },

  // {
  //   path: "/download",
  //   name: "DownloadPage",
  //   component: () =>
  //     import(/* webpackChunkName: "support" */ "../views/DownloadPage.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   if(to.path === "/") {
//     console.log(to.query)
//   }
//   next();
// });

export default router;
